import styled, { keyframes } from 'styled-components';

const transformTime = '1s';

const enterAnim = keyframes`
  0% {
    filter: blur(15px);
    opacity: 0;
  }

  50% {
    filter: blur(5px);
  }

  100% {
    filter: blur(0);
    opacity: 1;
  }
`;

export const ButtonWrapper = styled.div`
  animation: ${enterAnim} ${transformTime} 4s 1;
  animation-fill-mode: forwards;
  padding-bottom: 5vh;
  opacity: 0;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  flex-flow: column;

  width: 100vw;
  height: 100vh;
`;

export const Logo = styled.img`
  height: 396px;
  width: 396px;
`;

export const Video = styled.video`
  object-fit: cover;
  width: 100vw;
  height: 101vh;

  position: absolute;

  z-index: -1;
`;
