import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'gatsby';
import { Button } from 'amphitheatre';
import { createGlobalStyle } from 'styled-components';
import { HiArrowCircleRight } from 'react-icons/hi';
import { Content, ButtonWrapper, Video } from '../pages-styles/index.styled';

import Layout from '../components/Layout';
import VideoSrc from '../images/background.mp4';
import VideoSrcWebm from '../images/background.webm';

const Body = createGlobalStyle`
  body{
    background-color: ${({ theme }): string => theme.grey100};
  }
`;

const Home: React.FunctionComponent = () => {
  const [hideMenu, setHideMenu] = useState(true);

  function onVideoLoad(e: React.SyntheticEvent<HTMLVideoElement>): void {
    const video = e.target as HTMLVideoElement;
    video.play();
  }

  function onTimeUpdate(e: React.SyntheticEvent<HTMLVideoElement>): void {
    const video = e.target as HTMLVideoElement;

    if (video.currentTime >= 4.0 && hideMenu) {
      setHideMenu(false);
    }

    if (video.currentTime >= 12.0) {
      video.currentTime = 4.0;
      video.play();
    }
  }

  return (
    <Layout hidden={hideMenu}>
      <Helmet>
        <title>Wolfpack</title>
      </Helmet>
      <Body />
      <Content>
        <ButtonWrapper>
          <Link to="/aboutus" style={{ textDecoration: 'none' }}>
            <Button
              ghost
              style={{
                color: 'white',
                display: 'flex',
                alignItems: 'center',
                fontSize: '1.5rem',
                fontWeight: 'bold',
                padding: '0.5rem 3rem',
              }}
            >
              <HiArrowCircleRight style={{ marginRight: '0.25rem' }} /> ENTER
            </Button>
          </Link>
        </ButtonWrapper>
        <Video
          id="intro-video"
          playsInline
          onLoadedData={onVideoLoad}
          onTimeUpdate={onTimeUpdate}
        >
          <source src={VideoSrc} type="video/mp4" />
          <source src={VideoSrcWebm} type="video/webm" />
        </Video>
      </Content>
    </Layout>
  );
};

export default Home;
